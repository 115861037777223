import { defineStore } from 'pinia';
import ConversationService from '/entrypoints/services/ConversationService';
import MessageService from '/entrypoints/services/MessageService';
// import ErrorService from '/entrypoints/services/ErrorService.js';

export const useConversationStore = defineStore('conversationStore', {
  state: () => ({
    conversation: {
      messages: [],
      status: '',
      customer: '',
      firstMessage: {
        content: ''
      },
      content: {},
      contentHtml: ''
    },
    conversations: [],
  }),
  actions: {
    async fetchConversations(productId) {
      try {
        const response = await ConversationService.getAll({ product_id: productId });
        this.conversations = response.data;
      } catch (error) {
        // ErrorService.handleResponse(error, { message: 'Failed to load conversations.' });
      }
    },

    async getConversation(id) {
      try {
        const response = await ConversationService.get(id);
        this.setCurrentConversation(response.data);
      } catch (error) {
        // ErrorService.handleResponse(error, { message: 'Failed to load conversation.' });
      }
    },

    setCurrentConversation(conversation) {
      this.conversation = conversation;
    },

    async sendMessage() {
      try {
        const { id: conversationId, content, contentHtml } = this.conversation;
        const response = await MessageService.sendMessage(conversationId, content, contentHtml);

        this.conversation.messages.push(response.data);
        setTimeout(() => {
          const chatArea = document.getElementById('chat-area');
          chatArea.scroll({
            top: chatArea.scrollHeight,
            behavior: 'smooth'
          });
        }, 300);

      } catch (error) {
        // ErrorService.handleResponse(error, { message: 'Failed to send message.' });
      }
    },

    async updateConversationStatus(conversationId, newStatus) {
      try {
        const response = await ConversationService.update(conversationId, { status: newStatus.toLowerCase() });
        this.conversation = response.data;
      } catch (error) {
        // ErrorService.handleResponse(error, { message: 'Failed to update conversation.' });
      }
    },

    async viewConversation(conversation, router) {
      try {
        await this.markConversationAsRead(conversation.id);
        await this.getConversation(conversation.id);
        router.push(`/conversations/${conversation.id}`);
      } catch (error) {
        // ErrorService.handleResponse(error, { message: "Couldn't to load conversation." });
      }
    },

    async markConversationAsRead(conversationId) {
      try {
        await ConversationService.markAsRead(conversationId);
        this.conversations = this.conversations.map(c =>
          c.id === conversationId ? { ...c, hasUnreadMessages: false } : c
        );
      } catch (error) {
        // ErrorService.handleResponse(error, { message: 'Failed to update conversation.' });
      }
    },

    clearConversations() {
      this.conversations = [];
    }
  }
});
